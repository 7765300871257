import "./Timeline.scss";

const Timeline_middle = () => {
    return (
        <div className="timeline timeline--middle">

        </div>
    );
}

export default Timeline_middle;