import { Link, Outlet } from "react-router-dom";
import "./DefaultLayout.scss";
import { useState } from "react";

const DefaultLayout = () => {
    const [forceRender, setForceRender] = useState(false);

    return (
        <>
        {forceRender && <div className="forcerender"></div>}
        <div className="screen">
            <div className="layout">
                <nav className="layout__navigation">
                    <Link onClick={()=>setForceRender(!forceRender)} className={`layout__navigation__item ${(window.location.pathname === "/") && "layout__navigation__item--selected"}`} to="/">
                        <i class="fa-solid fa-house"></i>
                    </Link>
                    <Link onClick={()=>setForceRender(!forceRender)} className={`layout__navigation__item ${(window.location.pathname === "/projects") && "layout__navigation__item--selected"}`} to="/projects">
                        <i class="fa-solid fa-diagram-project"></i>
                    </Link>
                    <Link onClick={()=>setForceRender(!forceRender)} className={`layout__navigation__item ${(window.location.pathname === "/about") && "layout__navigation__item--selected"}`} to="/about">
                        <i class="fa-solid fa-user"></i>
                    </Link>
                </nav>
                <p className="layout__footer">Copyright &copy;{new Date().getFullYear()} Kris Ockers</p>
            </div>
            <div className="outlet">
                <Outlet />
            </div>
        </div>
        </>
    );
};

export default DefaultLayout;