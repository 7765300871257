import "./About.scss";
import { ReactSVG } from "react-svg";
import user from "../assets/layout/user.svg";

const About = () => {
    return (
        <div className="about">
            <header className="about__header">
                <figure className="about__header__figure">
                    <ReactSVG className="about__header__figure__img" src={user} />
                </figure>
                <h1 className="about__header__title">Name Surname</h1>
            </header>
            <div className="about__main"></div>
        </div>
    )
}

export default About;