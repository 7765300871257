import {createBrowserRouter} from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import About from "./pages/About";
import NotFound from "./components/NotFound";
import DefaultLayout from "./layout/DefaultLayout";


const router = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                path: '/',
                element: <Home />
            },
            {
                path: '/projects',
                element: <Projects />
            },
            {
                path: '/projects/:tag',
                element: <Projects />
            },
            {
                path: '/about',
                element: <About />
            },
        ]
    }, 

    {
        path: '/',
        element: <NotFound />
    }
])

export default router;
