import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';



import 'swiper/css';
import 'swiper/css/navigation';
import "./Home.scss";
import Timeline_start from "../components/Timeline/Timeline_start";
import Timeline_middle from "../components/Timeline/Timeline_middle";
import Timeline_end from "../components/Timeline/Timeline_end";

const Home = () => {
    
      
    return (
        <div className="home">
            <div className="home__wrapper">
                <div className="home__wrapper__timeline">
                <Swiper
                    // install Swiper modules
                    modules={[Navigation]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                    >
                    <SwiperSlide><Timeline_start /></SwiperSlide>
                    <SwiperSlide><Timeline_middle /></SwiperSlide>
                    <SwiperSlide><Timeline_middle /></SwiperSlide>
                    <SwiperSlide><Timeline_end /></SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Home;