const Projects = [
    {
        id: 1,
        name: "Project 1",
        description: "This is project 1",
        link: "https://www.google.com",
        github: "https://www.github.com",
        tags: ["react", "javascript", "html", "css"],
        date: "2020-01-01",
        gallery: [
            "https://via.placeholder.com/151",
            "https://via.placeholder.com/152",
            "https://via.placeholder.com/153",
        ]
    },
    {
        id: 2,
        name: "Project 2",
        description: "This is project 2",
        link: "https://www.google.com",
        github: "https://www.github.com",
        tags: ["react", "laravel", "html", "css"],
        date: "2020-01-01",
        gallery: [
            "https://via.placeholder.com/152",
        ]
    },{
        id: 3,
        name: "Project 3",
        description: "This is project 3",
        link: "https://www.google.com",
        github: "https://www.github.com",
        tags: ["react", "laravel", "html", "css"],
        date: "2020-01-01",
        gallery: [
            "https://via.placeholder.com/151",
            "https://via.placeholder.com/152",
            "https://via.placeholder.com/153",
        ]
    },
];

export default Projects;