import "./Project.scss";
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";

const Project = ({ project }) => {
    return(
        <div className="projects__project">
                        <header className="projects__project__header">
                            <div className="projects__project__header__top">
                                <h2 className="projects__project__header__top__title">{project.name}</h2>
                                <p className="projects__project__header__top__date">{project.date}</p>
                            </div>
                            <div className="projects__project__header__links">
                                {project.link && <a className="projects__project__header__links__link" href={project.link}><span></span> view online <span></span></a>}
                                {project.github && <a className="projects__project__header__links__link" href={project.github}><span></span> github <span></span></a>}
                            </div>
                        </header>
                        <div className="projects__project__main">
                            <div className="projects__project__main__content">
                                <p className="projects__project__main__content__description">{project.description}</p>
                                <div className="projects__project__main__content__gallery">
                                {project.gallery.length > 1 ?
                                    <Swiper
                                        modules={[Navigation]}
                                        spaceBetween={0}
                                        navigation
                                        slidesPerView={1}
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        {project.gallery.map((image) => {
                                            return <SwiperSlide><img className="projects__project__main__content__gallery__img" src={image} alt="" /></SwiperSlide>;
                                        })}
                                    </Swiper>
                                    :
                                    <img className="projects__project__main__content__gallery__img" src={project.gallery[0]} alt="" />
                                }
                                
                                </div>
                            </div>
                            <div className="projects__project__main__tags">
                                {project.tags.map((tag, index) => {
                                    if(index < 4){
                                    return (
                                        <Link to={'/projects/'+tag}> 
                                            <figure className="projects__project__main__tags__figure">
                                                <img className="projects__project__main__tags__figure__img" src={require(`../../assets/layout/${tag.toLowerCase()}.png`)} alt={tag} />
                                            </figure>
                                        </Link>
                                    )}
                                    return;
                                })}
                            </div>
                        </div>
                    </div>
    );
};

export default Project;