import "./Timeline.scss";

const Timeline_end = () => {
    return (
        <div className="timeline timeline--end">
            
        </div>
    );
}

export default Timeline_end;