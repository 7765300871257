import ProjectsData from "../data/Projects";
import Project from "../components/Project/Project";
import { Link, useParams } from "react-router-dom";

import "./Projects.scss";
import 'swiper/css';
import 'swiper/css/navigation';

const Projects = () => {
    const {tag} = useParams()
    const filteredProjects = ProjectsData.filter((project) => {
        if(tag){
            return project.tags.includes(tag);
        }
        return project;
    })

    return (
        <div className="projects">
            <header className="projects__header">
                {!tag && <i class="fa-solid fa-diagram-project projects__header__icon"></i>}
                {tag && <figure className="projects__header__figure">
                    <img className="projects__header__figure__img" src={require(`../assets/layout/${tag.toLowerCase()}.png`)} alt="logo" />
                </figure>}
                {tag && <Link to="/projects" className="projects__header__button">remove filter</Link>}
            </header>
            {filteredProjects.map((project) => {
                return (
                    <Project project={project} />
                )
            })}
        </div>
    )
}

export default Projects;