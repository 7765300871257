import "./Timeline.scss";

const Timeline_start = () => {
    return (
        <div className="timeline timeline--start">

        </div>
    );
}

export default Timeline_start;